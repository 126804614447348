import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SpiralEC';
  normal: boolean = true;

  constructor (){
    //this.normal = this.getSeperate()
  }
  ngOnInit() {
  }

  /*getSeperate() {
    return !window.location.pathname.toString().endsWith('edit')
  }*/

  titleBarDisplay() {
    return window.innerWidth > 600;
  }
}
