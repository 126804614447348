/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "./header/header.component.ngfactory";
import * as i9 from "./header/header.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "@angular/common/http";
import * as i12 from "./auth/http-headers.service";
import * as i13 from "@angular/router";
import * as i14 from "./footer/footer.component.ngfactory";
import * as i15 from "./footer/footer.component";
import * as i16 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "mat-toolbar", [["class", "mat-toolbar"], ["color", "primary"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(2, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ", "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Powered by Spiraldevs Automation Industries Pvt. Ltd."]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 2)._toolbarRows.length === 0); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.title; _ck(_v, 5, 0, currVal_3); }); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["fxFill", ""]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i6.FlexFillDirective, [i1.ElementRef, i7.StyleUtils, i6.FlexFillStyleBuilder, i7.MediaMarshaller], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "mat-toolbar", [["class", "title-small-screen mat-toolbar"], ["color", "primary"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(3, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 2, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(5, 0, null, 0, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Powered by Spiraldevs Automation Industries Pvt. Ltd."]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 3)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 3)._toolbarRows.length === 0); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.title; _ck(_v, 6, 0, currVal_3); }); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["id", "content-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-header", [], null, null, null, i8.View_HeaderComponent_0, i8.RenderType_HeaderComponent)), i1.ɵdid(7, 114688, null, 0, i9.HeaderComponent, [i10.MatDialog, i11.HttpClient, i12.HttpHeadersService, i13.Router], null, null), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i13.RouterOutlet, [i13.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-footer", [], null, null, null, i14.View_FooterComponent_0, i14.RenderType_FooterComponent)), i1.ɵdid(12, 114688, null, 0, i15.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleBarDisplay(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.titleBarDisplay(); _ck(_v, 4, 0, currVal_1); _ck(_v, 7, 0); _ck(_v, 9, 0); _ck(_v, 12, 0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["hey"]))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseBlock", 2]], null, 0, null, View_AppComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.normal; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i16.AppComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i16.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
