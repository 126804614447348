import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidenavCommunicationService {
  public sidenav: any;
  public sidenavChanges = new EventEmitter();

  emitChanges() {
    this.sidenavChanges.emit(this.sidenav);
  }
}
