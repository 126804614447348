<mat-toolbar color="primary" class="logout-toolbar">
  Log Out
  <span class="flex-spacer"></span>
  <button mat-button mat-dialog-close>x</button>
</mat-toolbar>
<p></p>
<span>Do you want to logout?</span>
<form novalidate >
  <mat-dialog-actions>
    <span class="flex-spacer"></span>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="onSubmit()">Log Out</button>
  </mat-dialog-actions>
</form>
