import { LoginComponent } from "../login/login.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
export class AuthGuard {
    constructor(dialog) {
        this.dialog = dialog;
        this.validation = 'false';
    }
    canActivate(next, state) {
        this.validation = localStorage.getItem('validation');
        if (this.validation == 'true')
            return true;
        else {
            sessionStorage.clear();
            localStorage.clear();
            this.dialog.open(LoginComponent);
            return false;
        }
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.MatDialog)); }, token: AuthGuard, providedIn: "root" });
