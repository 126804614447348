<mat-toolbar color="primary" class="login-toolbar">
  Login
  <span class="flex-spacer"></span>
  <button mat-button mat-dialog-close>&times;</button>
</mat-toolbar>

<form #loginForm="ngForm" novalidate>
  <mat-dialog-content>
    <p>
      <mat-form-field appearance="outline" style="margin-right: 5px;">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" type="text" [(ngModel)]="email" name="email">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput id="secret" placeholder="Password" showHideInput type="password" [(ngModel)]="password" name="password">
        <i matSuffix class="material-icons" showHideTrigger="secret" [showHideStatus]="{materialIcon: true, id: 'secret'}"></i>
      </mat-form-field>
    </p>
    <p>
      <mat-checkbox [(ngModel)]="remember" name="remember">Remember Me</mat-checkbox>
    </p>
  </mat-dialog-content>
  <p><a><input matInput style="color: blue;" (click)="forgotPasswordButton()" value="Forgot Password?"></a></p>
  <mat-dialog-actions>
    <span class="flex-spacer"></span>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button type="submit" (click)="onSubmit()">Login</button>
  </mat-dialog-actions>
</form>
<div class="alert" *ngIf="serverErrorLog">
  {{serverErrorLog}}
</div>
