import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {baseURL} from "./baseurl";
import {User} from "./user.model";
import {HttpHeadersService} from "../auth/http-headers.service";



@Injectable({
  providedIn: 'root'
})
export class UserService {
  selectedUser: User = {
    fullname: '',
    email: '',
    password: ''
  };



  constructor(public http: HttpClient, public httpService: HttpHeadersService) { }

  postUser(user: User){
    return this.http.post(baseURL + 'signup/', user, this.httpService.headers());
  }

  login(authCredentials) {
    return this.http.post(baseURL + 'login/authenticate/', authCredentials);
  }

  setToken(token: string, userType: string, company: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('validation', String(true));
    localStorage.setItem('userType', userType);
    localStorage.setItem('company', company);
  }

}

