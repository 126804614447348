import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LoginComponent} from "../login/login.component";
import {MatDialog} from "@angular/material";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private validation: string = 'false';

  constructor(private dialog: MatDialog){

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      this.validation = localStorage.getItem('validation');
      if (this.validation == 'true')
        return true;
      else {
        sessionStorage.clear();
        localStorage.clear();
        this.dialog.open(LoginComponent);
        return false;
      }
  }
}
