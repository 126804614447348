<ng-template [ngIf]="normal" [ngIfElse]="elseBlock">
<div id="content-wrap">
<div *ngIf="titleBarDisplay()">
  <mat-toolbar color="primary">
    <span>{{title}}, <small>Powered by Spiraldevs Automation Industries Pvt. Ltd.</small></span>
  </mat-toolbar>
</div>
<div *ngIf="!titleBarDisplay()" fxFill>
  <mat-toolbar color="primary" class="title-small-screen">
    <span>{{title}}<br><small>Powered by Spiraldevs Automation Industries Pvt. Ltd.</small></span>
  </mat-toolbar>
</div>
<div>
  <app-header></app-header>
</div>
<router-outlet></router-outlet>
</div>
<div class="footer">
  <app-footer></app-footer>
</div>
</ng-template>
<ng-template #elseBlock>
  <div>hey</div>
</ng-template>
