  <div *ngIf="secondaryMenuScreen()">
    <mat-toolbar color="accent" class="secondary-menu" >
      <mat-list-item><a mat-button (click)="sidebarOpenButtonClick()"><span><i class="fa fa-exchange" aria-hidden="true"></i></span></a></mat-list-item>
      <mat-list-item ><a mat-button routerLink="dashboard" >Dashboard</a></mat-list-item>
      <mat-list-item ><a mat-button routerLink="materials">Materials</a></mat-list-item>
      <mat-list-item ><a mat-button routerLink="work-order">Work Order</a></mat-list-item>
      <span class="flex-spacer"></span>
      <mat-list-item ><a mat-button routerLink="account">Account</a></mat-list-item>
    </mat-toolbar>
  <mat-divider ></mat-divider>
  </div>
  <div *ngIf="!secondaryMenuScreen()">
    <mat-toolbar color="accent" class="secondary-menu" >
      <mat-list-item><a mat-button (click)="sidebarOpenButtonClick()"><span><i class="fa fa-exchange" aria-hidden="true"></i></span></a></mat-list-item>
      <mat-list-item>
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item><a mat-button routerLink="dashboard" >Dashboard</a></button>
          <button mat-menu-item><a mat-button routerLink="materials">Materials</a></button>
          <button mat-menu-item><a mat-button routerLink="work-order">Work Order</a></button>
          <button mat-menu-item><a mat-button routerLink="account">Account</a></button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu">
          Menu
        </button>
      </mat-list-item>
    </mat-toolbar>
    <mat-divider ></mat-divider>
  </div>


  <div fxLayout="row" fxLayoutAlign="start stretch">

    <div class="sidebar" *ngIf="screenWidth()">
      <mat-sidenav-container fxFill>
        <mat-sidenav mode="side" [opened]="opened">
          <button mat-menu-item><a routerLink="new-service-connection">New Service Connection</a></button>
          <button mat-menu-item><a routerLink="disconnection">Disconnection</a></button>
          <button mat-menu-item><a routerLink="estimator">Budget Estimator</a></button>
          <!--<button mat-menu-item><a routerLink="security-deposit">Security Deposit</a></button>-->
          <button mat-menu-item><a routerLink>More Coming Soon...</a></button>
          </mat-sidenav>
      </mat-sidenav-container>
    </div>
    <div class="sidebar" *ngIf="!screenWidth()">
      <mat-sidenav-container fxFill>
        <mat-sidenav mode="side" [opened]="opened">
          <button mat-menu-item><a routerLink="new-service-connection">New Service Connection</a></button>
          <button mat-menu-item><a routerLink="disconnection">Disconnection</a></button>
          <button mat-menu-item><a routerLink="estimator">Budget Estimator</a></button>
          <!--<button mat-menu-item><a routerLink="security-deposit">Security Deposit</a></button>-->
          <button mat-menu-item><a routerLink>More Coming Soon...</a></button>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
    <div id="outlet" fxFlex>
        <router-outlet class="routerOutlet"></router-outlet>
    </div>
  </div>
