import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import * as CryptoJS from 'crypto-js';
import {baseURL} from "../../shared/baseurl";
import {MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../shared/user.service";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  email: any;
  sendButtonText: any = 'Send OTP';
  serverErrorLog: any = null;
  otpDiv: any = null;
  OTP: any = '';
  generatedOTP: any;
  otpSubmitDisable: any = true;
  otpTries: any = 5;
  resetPasswordDiv: any = false;
  newPassword: any;
  passwordStrengthDisable: any = true;
  passwordStrengthMessage: any = '';
  emailInputDisable: any = false;
  otpSendButtonDisable: any = false;
  wrongOTPMessage: any = '';
  showSpinner: any = false;

  constructor(public http: HttpClient, public userService: UserService, public dialogRef: MatDialogRef<ForgetPasswordComponent>
              ) {

  }

  ngOnInit() {
  }

  sendButtonPress() {
    var OTP = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000).toString();
    this.generatedOTP = OTP;
    this.showSpinner = true;

    var password = "msi1837cvh35s8a556d**^hvbY*mH12";
    var json = '{"OTP":' + JSON.stringify(OTP) + ',"email":' + JSON.stringify(this.email) + '}';
    var ctObj = CryptoJS.AES.encrypt(json, password).toString();
    ctObj = '{"authToken":' + JSON.stringify(ctObj) + '}';

    this.http.post(baseURL + 'login/reset-password/', ctObj).subscribe(
      res => {
        if(res['type']=='success') {
          this.sendButtonText = 'OTP Sent';
          this.otpSendButtonDisable = true;
          this.otpDiv = true;
          this.emailInputDisable = true;
          this.serverErrorLog = null;
          this.showSpinner = false;
        }
        else
          this.serverErrorLog = res['message'];
        this.showSpinner = false;
      },
      err => {
        this.serverErrorLog = err.name;
        this.serverErrorLog += ': ';
        this.serverErrorLog += err.message;
        this.showSpinner = false;
      }
    );
  }

  otpChange() {
    if(this.otpTries >0) {
      if (this.OTP != '' || this.OTP != null) {
        this.otpSubmitDisable = this.OTP.length != 6;
      }
    }
  }

  otpSubmitButtonClick() {
    if(this.otpTries > 0) {
      if (this.otpTries == 5) {
        this.wrongOTPMessage = '';
      }
      if (this.OTP == this.generatedOTP) {
        this.otpDiv = false;
        this.resetPasswordDiv = true;
      } else {
        this.otpTries--;
      }
      if (this.otpTries < 5) {
        if(this.otpTries == 0){
          this.resetPasswordDiv = false;
          this.otpSubmitDisable = true;
          this.wrongOTPMessage = 'You have reached maximum number of retries.';
        }
        else {
          this.wrongOTPMessage = 'Wrong OTP. You have ' + this.otpTries.toString() + ' tries left.';
        }
      }
    }
    else{
      this.resetPasswordDiv = false;
      this.otpSubmitDisable = true;
      this.wrongOTPMessage = 'You have reached maximum number of retries.';
    }
  }

  passwordStrengthCheck() {
    if(this.newPassword == '' || this.newPassword == null){
      this.passwordStrengthMessage = '';
      this.passwordStrengthDisable = true;
    }
    else if(this.newPassword.length < 6){
      this.passwordStrengthMessage = 'Password Very Small';
      this.passwordStrengthDisable = true;
    }
    else{
      this.passwordStrengthMessage = null;
      this.passwordStrengthDisable = false;
    }
  }

  resetPasswordSubmit() {
    var body = '{"password":' + JSON.stringify(this.newPassword) + ', "email":' + JSON.stringify(this.email) + '}';
    this.http.post(baseURL + 'login/reset-password/set-new-password/', body).subscribe(
      res => {
        if(res['type'] == 'success') {
          this.userService.setToken(res['token'], res['userType'], '');
          this.dialogRef.close();
          window.location.replace('#/dashboard');
          window.location.reload();
          this.serverErrorLog = null;
        }
        else{
          this.serverErrorLog = res['messaage'];
        }
      },
      err => {
        this.serverErrorLog = err.name;
        this.serverErrorLog += ': ';
        this.serverErrorLog += err.message;
      }
    );
  }
}
