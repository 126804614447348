import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class SidenavCommunicationService {
    constructor() {
        this.sidenavChanges = new EventEmitter();
    }
    emitChanges() {
        this.sidenavChanges.emit(this.sidenav);
    }
}
SidenavCommunicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SidenavCommunicationService_Factory() { return new SidenavCommunicationService(); }, token: SidenavCommunicationService, providedIn: "root" });
