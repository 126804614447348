import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material";
import { HttpClient } from "@angular/common/http";
import {UserService} from "../shared/user.service";
import {Router} from "@angular/router";
import {baseURL} from "../shared/baseurl";
import {HttpHeadersService} from "../auth/http-headers.service";
import {ForgetPasswordComponent} from "./forget-password/forget-password.component";
import {MatDialog} from "@angular/material/dialog";
import {retry} from "rxjs/operators";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  serverErrorLog: string = null;
  email: any = '';
  password: any = '';
  remember: any = true;

  constructor(public dialogRef: MatDialogRef<LoginComponent>,
              public http: HttpClient,
              public userService : UserService,
              public router: Router, public httpHeaders: HttpHeadersService,
              public dialog: MatDialog) { }

  ngOnInit() {

    if(!localStorage.getItem('token')){
      this.router.navigateByUrl('');
    }
  }
  onSubmit() {
    let body = '{"email":' + JSON.stringify(this.email) + ',"password":' + JSON.stringify(this.password) + ',"remember":' + JSON.stringify(this.remember) + '}';
    this.http.post(baseURL + 'login/authenticate/', body, this.httpHeaders.headers()).pipe(retry(10)).subscribe(
      res => {
        if(res['type'] == 'success') {
          this.userService.setToken(res['token'], res['userType'], '');
          this.dialogRef.close();
          window.location.replace('dashboard');
          window.location.reload();

          this.serverErrorLog = null;
        }
        else{
          this.serverErrorLog = res['message'];
        }

      },
      err => {
        this.serverErrorLog = err.name;
        this.serverErrorLog += ': ';
        this.serverErrorLog += err.message;
      }
    );
  }

  forgotPasswordButton() {
    this.dialogRef.close();
    this.dialog.open(ForgetPasswordComponent, {
      maxHeight:'85vh'
    });
  }
}
