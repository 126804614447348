import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import {Router} from "@angular/router";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public router: Router, public dialogRef: MatDialogRef<LogoutComponent>) { }

  ngOnInit() {
  }

  onSubmit() {
    localStorage.clear();
    sessionStorage.clear();
    this.dialogRef.close();
    this.router.navigateByUrl('');
    window.location.reload();

  }
}
