import { baseURL } from "./baseurl";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth/http-headers.service";
export class UserService {
    constructor(http, httpService) {
        this.http = http;
        this.httpService = httpService;
        this.selectedUser = {
            fullname: '',
            email: '',
            password: ''
        };
    }
    postUser(user) {
        return this.http.post(baseURL + 'signup/', user, this.httpService.headers());
    }
    login(authCredentials) {
        return this.http.post(baseURL + 'login/authenticate/', authCredentials);
    }
    setToken(token, userType, company) {
        localStorage.setItem('token', token);
        localStorage.setItem('validation', String(true));
        localStorage.setItem('userType', userType);
        localStorage.setItem('company', company);
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpHeadersService)); }, token: UserService, providedIn: "root" });
