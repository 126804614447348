import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
// import { HashLocationStrategy, LocationStrategy } from "@angular/common";

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatButtonModule, MatMenuModule } from "@angular/material";
import { AppRoutingModule } from "./app-routing/app-routing.module";
import { baseURL } from "./shared/baseurl";
import {UserService} from "./shared/user.service";
import {AuthGuard} from "./auth/auth.guard";
import { SecondaryMenuComponent } from './header/secondary-menu/secondary-menu.component';
import { NoHeaderFooterComponent } from './no-header-footer/no-header-footer.component';
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatDialogModule} from "@angular/material/dialog";
import {HttpClientModule} from "@angular/common/http";
import {LoginModule} from "./login/login.module";
import {LogoutModule} from "./logout/logout.module";
import {PaymentValidityGuard} from "./auth/payment-validity.guard";
import {SidenavCommunicationService} from "./header/secondary-menu/sidenav-communication.service";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SecondaryMenuComponent,
    NoHeaderFooterComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    MatToolbarModule,
    FlexModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatSidenavModule,
    ExtendedModule,
    MatDialogModule,
    HttpClientModule,
    LoginModule,
    LogoutModule
  ],
  providers: [
    {
      provide: 'baseURL', useValue: baseURL,
    },
    SidenavCommunicationService,
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    UserService,
    AuthGuard,
    PaymentValidityGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

