import {Component, OnInit} from '@angular/core';
import { MatDialog } from "@angular/material";
import { LoginComponent} from "../login/login.component";
import {LogoutComponent} from "../logout/logout.component";
import {baseURL} from "../shared/baseurl";
import {HttpClient} from "@angular/common/http";
import {HttpHeadersService} from "../auth/http-headers.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public token: string;
  public auth: boolean = false;
  serverErrorLog: string = null;

  constructor(public dialog: MatDialog, public http: HttpClient,
              public httpHeaders: HttpHeadersService, public router: Router) {
    this.token = localStorage.getItem('token');
    if (this.token == null) {
      this.token = '';
    }
    this.token = '{"token":' + JSON.stringify(this.token) + '}';
    this.validator(this.token);
    this.menu();

  }

  ngOnInit() {
  }

  validator(key: string){
    this.http.post(baseURL + 'login/validate/', key, this.httpHeaders.headers()).subscribe(
      res => {
        let jsonRes = JSON.parse(JSON.stringify(res));
        if(jsonRes.auth == true) {
          localStorage.setItem('validation', 'true');
          let ISTDate: Date = new Date(jsonRes.payment.IST);
          let ValidityDate: Date = new Date(jsonRes.payment.paymentValidTillDate);

          if (jsonRes.payment.paymentValidTillDate == '0' || jsonRes.payment.paymentValidTillDate == '1'){
            localStorage.setItem('valid', jsonRes.payment.paymentValidTillDate.toString());
            this.router.navigateByUrl('account');
          }
          else {
            if (ISTDate <= ValidityDate) {
              localStorage.setItem('valid', '01'); //True
            }
            else {
              localStorage.setItem('valid', '00'); //False
              this.router.navigateByUrl('account');
            }
          }
        }
        else if(jsonRes.auth == false) {
          sessionStorage.clear();
          localStorage.clear();
          this.auth = false;
        }
        this.serverErrorLog = null;
      },
      error => {
        this.serverErrorLog = error.name;
        this.serverErrorLog += ': ';
        this.serverErrorLog += error.message;
      }
    );
  }


  openLoginForm() {
    this.dialog.open(LoginComponent);
  }


  openLogoutForm() {
    this.dialog.open(LogoutComponent, {width: '400px', height: '200px'});
  }

  public menu() {
    if(localStorage.getItem('token') != null && localStorage.getItem('validation') == 'true')
        this.auth = true;
    else if(localStorage.getItem('validation') == 'false')
      this.auth = false;
    else
      this.auth = false;
  }
}

