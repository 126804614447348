<!--<span><img src="/assets/images/logo.png" height=30 width=41></span> logo here-->
<div class="menu-bar" fxShow="true" fxHide.lt-sm="true" >
  <mat-toolbar class="menu-bar primary-menu" >
<a mat-button routerLink=""><span class="fa fa-home fa-lg"></span> Home </a>
  <a mat-button routerLink="about-us"><span class="fa fa-info fa-lg"></span> About </a>
  <a mat-button routerLink="contact"><span class="fa fa-address-card fa-lg"></span> Contact </a>

  <span class="flex-spacer"></span>
  <a mat-button (click)="openLoginForm()" *ngIf="!auth"><span class="fa fa-sign-in fa-lg">Login</span></a>
  <a mat-button (click)="openLogoutForm()" *ngIf="auth">Logout</a>
  </mat-toolbar>
</div>
<div class="menu-bar" fxShow="false" fxShow.lt-sm="true">
  <mat-toolbar class="menu-bar primary-menu" >
    <a mat-button routerLink=""><span class="fa fa-home fa-lg"></span> Home </a>
    <a mat-button routerLink="about-us"><span class="fa fa-info fa-lg"></span> About </a>
    <a mat-button routerLink="contact/"><span class="fa fa-address-card fa-lg"></span> Contact </a>

    <span class="flex-spacer"></span>
    <a mat-button (click)="openLoginForm()" *ngIf="!auth"><span class="fa fa-sign-in fa-lg">Login</span></a>
    <a mat-button (click)="openLogoutForm()" *ngIf="auth">Logout</a>
  </mat-toolbar>
</div>
<mat-divider></mat-divider>
<app-secondary-menu *ngIf="auth"></app-secondary-menu>
<div class="alert" *ngIf="serverErrorLog">
  {{serverErrorLog}}
</div>

