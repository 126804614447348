import {Component, OnInit} from '@angular/core';
import {SidenavCommunicationService} from "./sidenav-communication.service";

@Component({
  selector: 'app-secondary-menu',
  templateUrl: './secondary-menu.component.html',
  styleUrls: ['./secondary-menu.component.css'],
})
export class SecondaryMenuComponent implements OnInit {

  public opened:Boolean;
  public screenWidthPx: any;
  constructor(public sidenavCommunicationService: SidenavCommunicationService) {
    this.opened = this.screenWidth();
    this.sidenavCommunicationService.sidenav = this.opened;
    this.sidenavCommunicationService.emitChanges();

  }

  ngOnInit() {
  }

  screenWidth() {
    this.screenWidthPx = window.innerWidth;
    return this.screenWidthPx > 880;
  }

  secondaryMenuScreen() {
    return window.innerWidth > 600;
  }

  sidebarOpenButtonClick() {
    this.opened  = !this.opened;
    this.sidenavCommunicationService.sidenav = this.opened;
    this.sidenavCommunicationService.emitChanges();
    return this.opened;
  }
}
