<!--
<div class="container footer"
     fxLayout="row wrap"
     fxLayout.lt-md="column"
     fxLayoutAlign="center center"
     fxLayoutGap="10px">

  <div fxFlex="100" fxFlex.gt-sm="50">
    <div fxLayout="row">
      <div fxFlex="40" fxFlexOffset="20px">
        <mat-list>
          <mat-nav-list ><a mat-button class="white-button" routerLink="terms">Terms&Conditions</a></mat-nav-list>
          <mat-nav-list ><a mat-button class="white-button" routerLink="privacy">Privacy Policy</a></mat-nav-list>
        </mat-list>
      </div>
      <div fxFlex="55">
        <address style="text-size: 80%">
          <br>Raiganj, Goalpara (Dostimore) <br> West Bengal, India <br>
          <i class="fa fa-phone">Phone</i>: {{phone}}<br>
          <i class="fa fa-envelope">Email</i>:
          <a href="mailto:{{email}}" class="white-button">{{email}}</a>
        </address>
      </div>
    </div>
  </div>
  -->
<!--
<div fxFlex="100" fxFlex.gt-sm="45">
  <div style="text-align:center">
    <a mat-icon-button class="btn-google-plus" href="http://google.com/+"><i class="fa fa-google-plus fa-lg"></i></a>
    <a mat-icon-button class="btn-facebook white-button" href="http://www.facebook.com/profile.php?id="><i class="fa fa-facebook fa-lg"></i></a>
    <a mat-icon-button class="btn-linkedin white-button" href="http://www.linkedin.com/in/"><i class="fa fa-linkedin fa-lg"></i></a>
    <a mat-icon-button class="btn-twitter white-button" href="http://twitter.com/"><i class="fa fa-twitter fa-lg"></i></a>
    <a mat-icon-button class="btn-youtube white-button" href="http://youtube.com/"><i class="fa fa-youtube fa-lg"></i></a>
    <a mat-icon-button class="btn-mail" href="mailto:"><i class="fa fa-envelope-o fa-lg"></i></a>
  </div>
</div>-->

  <div class="container footer" fxLayoutAlign="center center">
    <div>© Copyright 2019 </div>
    <div style="margin-left: 30px;">
      <button mat-button [matMenuTriggerFor]="pages">Quick Links</button>
      <mat-menu #pages="matMenu">
        <button mat-menu-item><a routerLink="links/privacy-policy">Privacy Policy</a></button>
        <button mat-menu-item><a routerLink="links/terms&conditions">Terms & Conditions</a></button>
        <button mat-menu-item><a routerLink="links/disclaimer">Disclaimer</a></button>
        <button mat-menu-item><a routerLink="links/copyright-policy">Copyright Policy</a></button>
        <button mat-menu-item><a routerLink="links/cookie-policy">Cookie Policy</a></button>
        <button mat-menu-item><a href="https://spiraldevs.com">Developed and Powered By</a></button>
      </mat-menu>
    </div>
  </div>
<!--</div>-->
