import {Injectable} from '@angular/core';
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HttpHeadersService {


  constructor() { }


  headers() {
    var token = localStorage.getItem('token');
    var userType = localStorage.getItem('userType');
    var company = localStorage.getItem('company');

    if (token == null) {
      token = '';
    }
    if (userType == null) {
      userType = '';
    }
    if (company == null) {
      company = '';
    }

    var head: string = '{"token":' + JSON.stringify(token) + ', "userType":' + JSON.stringify(userType) + ', "company":' + JSON.stringify(company) + '}';
    var header_object_token = new HttpHeaders().set("Authorization", head);


    return {
      headers: header_object_token
    };
  }
}

