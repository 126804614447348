import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./login.component";
import {ForgetPasswordComponent} from "./forget-password/forget-password.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {ShowHidePasswordModule, ShowHideService} from "ngx-show-hide-password";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [LoginComponent, ForgetPasswordComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    FormsModule,
    MatDialogModule,
    MatInputModule,
    ShowHidePasswordModule,
    MatCheckboxModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [LoginComponent, ForgetPasswordComponent],
  providers: [ShowHideService]
})
export class LoginModule { }
