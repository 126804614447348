import { Routes } from "@angular/router";
import { AuthGuard } from "../auth/auth.guard";
import {PaymentValidityGuard} from "../auth/payment-validity.guard";


export const routes: Routes = [
  {path: '', loadChildren: () => import('../home/home.module').then(m=>m.HomeModule)},
  {path: 'home', redirectTo: '', pathMatch: 'full'},
  {path: 'account', loadChildren: () => import('../account/account.module').then(m=>m.AccountModule), canActivate:[AuthGuard]},
  {path: 'dashboard', loadChildren: () => import('../header/secondary-menu/dashboard/dashboard.module').then(m=>m.DashboardModule),
    canActivate:[AuthGuard, PaymentValidityGuard]},
  {path: 'materials', loadChildren: () => import('../header/secondary-menu/materials/materials.module').then(m=>m.MaterialsModule),
    canActivate:[AuthGuard, PaymentValidityGuard]},
  {path: 'billing', loadChildren: () => import('../header/secondary-menu/billing/billing.module').then(m=>m.BillingModule),
    canActivate:[AuthGuard, PaymentValidityGuard]},
  {
    path: 'work-order',
    loadChildren: () => import('../header/secondary-menu/work-order/work-order.module').then( m => m.WorkOrderModule),
    canActivate: [AuthGuard, PaymentValidityGuard]
  },
  {path: 'purchase-order-integrated', loadChildren: () => import('../header/secondary-menu/purchase-order-integrated/purchase-order-integrated.module').then(m=>m.PurchaseOrderIntegratedModule),
    canActivate:[AuthGuard, PaymentValidityGuard]},
  {path: 'purchase-order', loadChildren: () => import('../header/secondary-menu/purchase-order/purchase-order.module').then(m=>m.PurchaseOrderModule),
    canActivate:[AuthGuard, PaymentValidityGuard]},
  {path: 'security-deposit', loadChildren: () => import('../header/secondary-menu/security-money/security-money.module').then(m=>m.SecurityMoneyModule),
    canActivate:[AuthGuard, PaymentValidityGuard]},
  {path: 'estimator', loadChildren: () => import('../header/secondary-menu/estimator/estimator.module').then(m=>m.EstimatorModule),
    canActivate:[AuthGuard, PaymentValidityGuard]},
  {path: 'links', loadChildren: () => import('../others/links.module').then(m=>m.LinksModule)},
  {path: 'about-us', loadChildren: () => import('../others/about-us/about-us.module').then(m=>m.AboutUsModule)},
  {path: 'contact', loadChildren: () => import('../others/contact-us/contact-us.module').then(m=>m.ContactUsModule)},
  {path: 'partial-billed', loadChildren: () => import('../header/secondary-menu/dashboard/partial-billed/partial-billed.module').then(m=>m.PartialBilledModule),
    canActivate: [AuthGuard, PaymentValidityGuard]},
  {path: 'complete-billed', loadChildren: () => import('../header/secondary-menu/dashboard/completed-and-billed/completed-and-billed.module').then(m=>m.CompletedAndBilledModule),
    canActivate:[AuthGuard, PaymentValidityGuard]},
  {path: 'unbilled', loadChildren: () => import('../header/secondary-menu/dashboard/unbilled/unbilled.module').then(m=>m.UnbilledModule),
    canActivate: [AuthGuard, PaymentValidityGuard]},
  {path: 'pending-bill', loadChildren: () => import('../header/secondary-menu/dashboard/pending/pending.module').then(m=>m.PendingModule),
    canActivate: [AuthGuard, PaymentValidityGuard]},
  {path: 'new-service-connection', loadChildren: () => import('../header/secondary-menu/new-service-connection/new-service-connection.module').then(m=>m.NewServiceConnectionModule),
    canActivate: [AuthGuard, PaymentValidityGuard]},
  {path: 'disconnection', loadChildren: () => import('../header/secondary-menu/disconnection/disconnection.module').then(m=>m.DisconnectionModule),
    canActivate:[AuthGuard, PaymentValidityGuard]},
  {path: 'meter-replacement', loadChildren: () => import('../header/secondary-menu/meter-replacement/meter-replacement.module').then(m=>m.MeterReplacementModule),
    canActivate:[AuthGuard, PaymentValidityGuard]}
];
