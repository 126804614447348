<div>
  <mat-toolbar color="primary">
    Reset Password
    <span class="flex-spacer"></span>
    <button mat-button mat-dialog-close>X</button>
  </mat-toolbar>
</div>
<p>Enter your email Address.<br>We will send you OTP.</p>
<mat-form-field appearance="outline">
  <mat-label>Enter Email</mat-label>
  <input matInput placeholder="Enter Email to send reset link" [disabled]="emailInputDisable" [(ngModel)]="email">
</mat-form-field>
<button mat-raised-button color="primary" (click)="sendButtonPress()" [disabled]="otpSendButtonDisable">{{sendButtonText}}</button>
<button mat-button *ngIf="showSpinner"><mat-spinner></mat-spinner></button>
<div *ngIf="otpDiv">
  <label>Enter 6 digit OTP</label>
  <input placeholder="Enter 6 digit OTP" name="otp" [(ngModel)]="OTP" (ngModelChange)="otpChange()">
  <button mat-raised-button color="primary" [disabled]="otpSubmitDisable" (click)="otpSubmitButtonClick()">Submit</button>
  <div>{{wrongOTPMessage}}</div>
</div>
<div *ngIf="resetPasswordDiv">
  <mat-form-field appearance="outline">
    <mat-label>Enter new password</mat-label>
    <input matInput type="password" name="newPassword" [(ngModel)]="newPassword" (ngModelChange)="passwordStrengthCheck()">
  </mat-form-field>
  <button mat-raised-button color="primary" [disabled]="passwordStrengthDisable" (click)="resetPasswordSubmit()">Change Password</button>
  <div>
    {{passwordStrengthMessage}}
  </div>
</div>
<div *ngIf="serverErrorLog">
  {{serverErrorLog}}
</div>
