import { HttpHeaders } from "@angular/common/http";
import * as i0 from "@angular/core";
export class HttpHeadersService {
    constructor() { }
    headers() {
        var token = localStorage.getItem('token');
        var userType = localStorage.getItem('userType');
        var company = localStorage.getItem('company');
        if (token == null) {
            token = '';
        }
        if (userType == null) {
            userType = '';
        }
        if (company == null) {
            company = '';
        }
        var head = '{"token":' + JSON.stringify(token) + ', "userType":' + JSON.stringify(userType) + ', "company":' + JSON.stringify(company) + '}';
        var header_object_token = new HttpHeaders().set("Authorization", head);
        return {
            headers: header_object_token
        };
    }
}
HttpHeadersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpHeadersService_Factory() { return new HttpHeadersService(); }, token: HttpHeadersService, providedIn: "root" });
